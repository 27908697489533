import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import FusionCharts from 'fusioncharts';

FusionCharts.options.license({
    key: '8gG1kvdC8B2E6C2G3C2F2I1A3D2B5D6D3H-9jI-8G2C9uuyerF2D1iC-21hD1B5D1B2C2A17A11A12A4D4C5E2E1H4B3B2B-31bbA3C4E3rH2C1C5gjC-13D2D5F1H-8H-7lB8A5C7oqsH4G1B8D3B6E2F6D1D3G4F2tC-16D-13B2A3ME2B-7ziA2D4E1xkB4e1B2B11aarA4B2C3A1E1A1C2A6E1E5D1G4I4J2h==',
    creditLabel: false,
});

ReactDOM.render(<App />, document.getElementById('root'));
